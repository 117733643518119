
@font-face {
  font-family: 'sf-pro-text';
  src: local('sf-pro-text'), url('./SFProText-Light.woff') format('woff'),
       local('sf-pro-text'), url('./SFProText-Light.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'sf-pro-text';
  src: local('sf-pro-text'), url('./SFProText-Regular.woff') format('woff'),
       local('sf-pro-text'), url('./SFProText-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'sf-pro-text';
  src: local('sf-pro-text'), url('./SFProText-Medium.woff2') format('woff'),
       local('sf-pro-text'), url('./SFProText-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'sf-pro-text';
  src: local('sf-pro-text'), url('./SFProText-Semibold.woff') format('woff'),
       local('sf-pro-text'), url('./SFProText-Semibold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'sf-pro-text';
  src: local('sf-pro-text'), url('./SFProText-Bold.woff') format('woff'),
       local('sf-pro-text'), url('./SFProText-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'sf-pro-text';
  src: local('sf-pro-text'), url('./SFProText-Heavy.woff') format('woff'),
       local('sf-pro-text'), url('./SFProText-Heavy.woff2') format('woff2');
  font-style: normal;
  font-weight: 800;
}
